import * as React from "react"
import "../utils/global.css"
import sticker from "../images/sportspullen.png"
import logo from "../images/SportSupport_logo.png"
import logotext from "../images/SportSupport_textlogo_inv.png"
import sportspullen from "../images/sportspullen.jpg"
import containers from "../images/containers.jpg"
import magazijn from "../images/magazijn.jpg"
import smllogo from "../images/SML_logo.png"
import upwardlogo from "../images/Upward_logo.png"
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin } from 'react-icons/fa';




// markup
export default function IndexPage() {

  return (
    <main > 
      <title>SportSupport Arnhem</title>
      <div className="grid-container">
        <div className="logo">
        <a href="#home"><img alt="SportSupport Arnhem"  src={logotext}  className="logoImg" /></a>
        </div>
        <div className="menu">
          <nav>
            <ul>
              <li><a href="#aanvragen">Aanvragen</a></li>
              <li><a href="#inleveren">Inleveren</a></li>
              <li><a href="#faq">Faq</a></li>
              <li><a href="#over">Over ons</a></li>
           </ul>
          </nav>
        </div>
        <div className="one-full">
          <img alt="SportSupport Arnhem" src={sticker} />
        </div>
        <div className="two-full">
        <h1>Gun sportspullen een tweede leven! </h1>
        <h2>Wat doet Sport Support?</h2>
        <p>Sport Support zamelt gebruikte maar goede sportkleding en -spullen in voor kinderen en volwassenen die deze goed kunnen gebruiken. Zo wil Sport Support meer mensen aan het sporten helpen. Dit is duurzaam, eerlijk en wel zo sportief!</p> 
        </div>
        
        <div className="three-full"><a className="anchor" id="aanvragen"></a>
        <h2>Sportspullen aanvragen</h2>
         <h3>Wil jij gaan sporten? Dan kunnen wij jou vast aan sportkleren en sportspullen helpen.  </h3>
          <p>Sport Support geeft </p>
          <ul>
            <li>goede sportkleding en sportspullen</li>
            <li>voor verschillende sporten</li>
            <li>aan kinderen en volwassenen die deze goed kunnen gebruiken</li>
            <li>gratis en zonder inkomenscheck</li>
          </ul>
          <p>Je kunt sportspullen aanvragen door te mailen naar info@sportsupportarnhem.nl of een DM te sturen via de socials.  </p>
        </div>
        
        <div className="four-full"><img alt="Sportspullen" src={magazijn} /></div>
        <div className="five-left"><a className="anchor" id="inleveren"></a>
          <h2>Sportspullen inleveren </h2>
          <p>Iedereen moet kunnen sporten, maar voor lang niet alle Arnhemmers is de aanschaf van sportkleding of -benodigdheden vanzelfsprekend.</p>  
          <p>Sport Support zamelt goede sportkleding en -spullen in voor kinderen en volwassenen die deze goed kunnen gebruiken. Zo wil Sport Support meer mensen aan het sporten helpen.</p>  
          <p> <b>Dit is duurzaam, eerlijk en wel zo sportief!</b> </p> <br />
        <img alt="containers" src={containers} />
        </div>
        <div className="five-right">
          <p>Heb jij goede sportspullen die je niet meer gebruikt en aan een ander gunt, dan kun je ze inleveren bij Sport Support.</p>
          <h4>Wat</h4>
          <p>Schone, goede sportkleding en sportspullen (denk aan schoenen, scheenbeschermers, rackets) voor verschillende sporten (dus bijvoorbeeld ook judopakken) van kinderen en volwassenen</p>
          <h4>Waar</h4>
          <p>In de speciale grijs-witte Sport Support containers </p>
            <ul>
              <li>Sportpark de Bakenberg, Upward en SML</li>
              <li>Zwembad Valkenhuizen </li>
              <li>Zwembad De Grote Koppel</li> 
              <li>Crossfit Arnhem </li>
              <li>Pegasus fitness </li>
              <li>Sportpark Cranenvelt, VDZ </li>
              <li>Playground Thialf </li>
            </ul>

         </div>
        <div className="six-full"><a className="anchor" id="faq"></a><h2>Veel gestelde vragen</h2>
          <div>
            <h4>Q: Voor wie is Sport Support?</h4>
            <p><b>A: Stichting Sport Support is voor Arnhemse kinderen en volwassen die graag willen sporten en niet voldoende financiële middelen hebben om de benodigde sportpullen te kopen.   </b><br />
              Voor lang niet alle Arnhemmers is de aanschaf van sportkleding of -benodigdheden vanzelfsprekend, maar om te kunnen sporten heb je vaak sportspullen nodig. Dat kan kleding zijn, zoals een trainingsbroek, voetbalschoenen of judo pak, maar ook sportmateriaal, zoals een hockeystick, tennisracket of zwemschoenen. Niet iedereen kan deze spullen betalen. Stichting Sport Support wil zowel kinderen als volwassenen aan de nodige sportspullen helpen.
            </p>
          </div>
          <div>
            <h4>Q: Heeft Sport Support een inkomenscheck/-toets?  </h4>
            <p><b>A: Nee, Sport Support wil graag kleding en benodigdheden voor iedereen beschikbaar stellen die daar behoefte aan heeft.</b><br />
              Sport Support kijkt naar behoefte en niet naar inkomen. Sport Support wil er zijn voor alle Arnhemmers die moeite hebben met de aanschaf van sportspullen.
            </p></div>
          <div>
            <h4>Q: Wat voor sportspullen kan ik inleveren bij Sport Support? </h4>
            <p><b>A: ALLE soorten sportkleding en -materialen van ALLE sporten voor ALLE  leeftijden zijn welkom bij Sport Support, mits bruikbaar, dus schoon en goed voor een tweede (of zoveelste) ronde. </b><br />
              Om te sporten heb je meestal verschillende sportspullen nodig. Sport Support zamelt alle soorten sportkleding in, zoals trainingsbroeken, voetbalschoenen of judopakken. Ander sportmateriaal, zoals een hockeystick, tennisracket of zwemschoenen, zijn ook soms nodig om te sporten. Niet iedereen kan deze spullen betalen. Sport Support wil zowel kinderen als volwassenen aan de nodige sportspullen helpen.
            </p></div>
          <div>
            <h4>Q: Waar moeten de ingeleverde sportspullen aan voldoen?  </h4>
            <p><b>A: Spullen moeten vooral SCHOON, NETJES en GOED BRUIKBAAR zijn voor een volgende ronde.  </b><br />
              Stel jezelf de vraag bij inleveren van je item: zou ik er zelf blij van worden? Ja?! Dan zijn Sport Support en de ontvanger dat ook!
            </p></div>
          <div>
            <h4>Q: Mijn sportpak heeft geen maat (meer), kan ik deze inleveren?  </h4>
            <p><b>A: Ja, graag!   </b><br />
              Sport Support zoekt de maat uit.
            </p></div>
          <div>
            <h4>Q: De naam van mijn kind staat op de kleding, kan ik het kledingstuk wel inleveren? </h4>
            <p><b>A: Ja, graag!  </b><br />
              Sport Support zal proberen deze “professioneel” te verwijderen. Als dat niet lukt is het aan de ontvanger om hier een keuze in te maken.
            </p></div>
          <div>
            <h4>Q: Hoe kan ik sportspullen aanvragen?  </h4>
            <p><b>A: Spullen kunnen op verschillende manieren aangevraagd worden. </b><br />
              Wie behoefte heeft aan sportkleding of spullen kan deze op verschillende manieren ontvangen.
            </p>
            <ol>
               <li>Een sportvereniging kan kinderen en volwassenen sportkleding van Sport Support aanbieden als zij zich aanmelden bij die vereniging.</li>
                <li>Kinderen en volwassenen kunnen bij behoefte aan sportkleding en -benodigdheden zich via e-mail info@sportsupportarnhem.nl of onze socials melden bij Sport Support</li>
                <li>Via instanties kan de behoefte aan sportspullen van personen en/of groepen kenbaar gemaakt worden aan Sport Support.</li>
              </ol>
            <p>Kleding kan op afspraak gegeven worden. In sommige gevallen zal ervoor gekozen worden om sportspullen aan (heel) specifieke sportverenigingen te doneren.
            </p></div>
          <div><h4>

            Q: Ik wil graag helpen bij Sport Support, hoe kan ik mij aanmelden?  </h4>
            <p><b>
              A: Mail Sport Support: info@sportsupportarnhem.nl</b><br />
            </p></div>


        </div>
        <div className="footer-left"><img alt="SportSupport Arnhem" src={logo} /></div>
        <div className="footer-right"><a className="anchor" id="over"></a>
          <p>Sport Support is een inititatief van:</p>
          <p className="clublogo"><img alt="Upward Arnhem" src={upwardlogo}  /><img alt="SML Arnhem" src={smllogo}  /></p>
          <p>
            <a href="https://www.instagram.com/sportsupportarnhem/" className="socialicons"><FaInstagramSquare /></a>
            <a href="https://www.facebook.com/Sport-Support-Arnhem-100354962420241 " className="socialicons"><FaFacebookSquare /></a>
            <a href="https://www.linkedin.com/in/sport-support-arnhem-805535213/" className="socialicons"><FaLinkedin /></a>
          </p>
          <p><br/>KvK: 83019944 </p>
        </div>
        <div className="footer-full"><h3>Wil je meehelpen, mail naar <a href="mailto:info@sportsupportarnhem.nl">info@sportsupportarnhem.nl</a></h3>
          <p><br/>Met dank aan: Harald Pieper, Dennis Kobus</p>
        </div>
      </div>

    </main>
  )
}


